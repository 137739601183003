<template>
  <div class="configuration-iframe-app" v-loading="frameLoading">
    <el-button
      class="fixed-content"
      :class="{ 'btn-allover-position': showAllover }"
      @click="toggleShowAllover"
      @keyup.esc="toggleShowAllover"
      :type="showAllover ? 'info' : 'primary'"
      >{{ showAllover ? '还原' : '全屏显示' }}</el-button
    >
    <iframe :src="url" ref="iframeRef" @load="onload" :class="{ 'allover-screen': showAllover }"></iframe>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { userCurrentProject } from '@/layout/messageCenter/user-info';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'ConfigurationImport',
  setup() {
    console.log(userCurrentProject.value);
    const staticUrl =
      process.env.NODE_ENV === 'development'
        ? `http://dev-configuration.welink.qq.com:1024?projectId=${userCurrentProject.value?.id || 0}#/applications`
        : `/configuration-studio?projectId=${userCurrentProject.value?.id || 0}#/applications`;

    const iframeRef = ref(null);
    const url = ref('');

    const frameLoading = ref(true);
    const closeLoading = () => {
      frameLoading.value = false;
    };

    const onload = () => {
      console.log('onload');
      closeLoading();
    };
    setTimeout(() => {
      closeLoading();
    }, 15 * 1000);
    setTimeout(() => {
      url.value = staticUrl;
    }, 10);

    const showAllover = ref(false);
    const toggleShowAllover = () => {
      showAllover.value = !showAllover.value;
    };

    watch(showAllover, (nn) => {
      if (nn) {
        ElMessage.info('点击键盘ESC按键退出全屏模式');
      }
    });

    return {
      url,
      iframeRef,
      onload,
      showAllover,
      toggleShowAllover,
      frameLoading,
      closeLoading,
    };
  },
});
</script>

<style lang="scss">
.configuration-iframe-app {
  width: 100%;
  height: calc(100vh - 140px);
  //background-color: rgba(0, 0, 0, 0.2);
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    //position: fixed;
    //top: 0;
    //left: 0;
    background-color: white;
  }
  .allover-screen {
    position: fixed;
    top: 0;
    left: 0;
  }
  .fixed-content {
    position: absolute;
    width: 90px;
    height: 32px;
    //background-color: #006eff;
    top: 0;
    right: 50px;
    z-index: 200;
    margin-top: 10px;
  }
  .btn-allover-position {
    top: -50px;
    right: 250px;
    z-index: 9200;
    height: 29px;
  }
}
</style>
